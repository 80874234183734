.editGallery {
  &_area {
    padding: 16px;
  }
  &_top,
  &_bottom {
    width: 480px;
    margin: 0 auto;
    position: relative;
    .swiper {
      color: #9e9e9e;
      &-button {
        &-next,
        &-prev {
          color: #9e9e9e;
        }
        &-next {
          right: -40px;
        }
        &-prev {
          left: -40px;
        }
      }
    }
  }
  &_bottom {
    .swiper {
      &-button {
        &-next,
        &-prev {
          &::after {
            font-size: 3.2rem;
          }
        }
        &-next {
          right: -32px;
        }
        &-prev {
          left: -32px;
        }
      }
    }
  }
  &_inner {
    width: 480px;
    margin: 0 auto;
    overflow: hidden;
  }
}

.swiper-container {
  //スライダー
  &.slider {
    width: 480px !important;
    height: 270px !important;
    overflow: hidden !important;
    margin: 0 auto;
    img {
      width: 480px;
      height: 270px;
      object-fit: contain;
    }
  }

  //サムネイル
  &.slider-thumbnail {
    width: 120px !important;
    height: 68px !important;
    margin: 10px auto 0;
    .swiper-wrapper {
      .swiper-slide {
        width: 120px !important;
        height: 68px !important;
        opacity: 0.3;
        overflow: hidden;
        img {
          width: 120px;
          height: 68px;
          object-fit: contain;
        }
        &.swiper-slide-active {
          opacity: 1;
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-sp) {
  .editGallery {
    &_area {
      padding: 16px;
    }
    &_top,
    &_bottom {
      max-width: 480px;
      width: 100%;
      margin: 0 auto;
      position: relative;
      .swiper {
        color: #9e9e9e;
        &-button {
          &-next,
          &-prev {
            color: #9e9e9e;
          }
          &-next {
            right: -20px;
          }
          &-prev {
            left: -20px;
          }
        }
      }
    }
    &_bottom {
      .swiper {
        &-button {
          &-next,
          &-prev {
            &::after {
              font-size: 3.2rem;
            }
          }
          &-next {
            right: -16px;
          }
          &-prev {
            left: -16px;
          }
        }
      }
    }
    &_inner {
      max-width: 480px;
      width: 100%;
      margin: 0 auto;
      overflow: hidden;
    }
  }

  .swiper-container {
    //スライダー
    &.slider {
      max-width: 480px !important;
      width: 100% !important;
      max-height: 270px !important;
      height: auto !important;
      overflow: hidden !important;
      margin: 0 auto;
    }

    //サムネイル
    &.slider-thumbnail {
      max-width: 120px !important;
      width: 120px !important;
      max-height: 68px !important;
      height: auto !important;
      margin: 10px auto 0;
      .swiper-wrapper {
        .swiper-slide {
          max-width: 120px !important;
          width: 100% !important;
          max-height: 68px !important;
          height: auto !important;
          opacity: 0.3;
          overflow: hidden;
          &.swiper-slide-active {
            opacity: 1;
          }
        }
      }
    }
  }
}
