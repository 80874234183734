.supervisor {
  // .supervisor_top

  &_top {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 20px;
    background: #ebf5fe;
  }

  // .supervisor_topLeft

  &_topLeft {
    > p {
      margin-top: 0;
      font-weight: bold;
    }
  }

  // .supervisor_topRight

  &_topRight {
    > p {
      margin-top: 0;
      text-align: right;
    }
  }
}

@media screen and (max-width: $breakpoint-sp) {
  .supervisor {
    // .supervisor_top

    &_top {
      padding: 16px;
      flex-flow: column nowrap;
    }

    // .supervisor_topLeft

    &_topLeft {
      align-self: flex-start;
      > p {
      }
    }

    // .supervisor_topRight

    &_topRight {
      align-self: flex-end;
      margin-top: 20px;
      > p {
      }
    }
  }
}

.supervisor {
  // .supervisor_bottom

  &_bottom {
    margin-top: 60px;
  }

  // .supervisor_bottomTitle

  &_bottomTitle {
    background: #0066bb;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 5px 5px 0 0;
    > p {
      margin-top: 0;
      font-size: 1.7rem;
      font-weight: bold;
      color: white;
    }
  }

  // .supervisor_bottomMedia

  &_bottomMedia {
    display: flex;
    border: solid 1px #e5e5e5;
    padding: 30px 40px;
    border-radius: 0 0 5px 5px;
    border-top: none;
  }

  // .supervisor_bottomLeft

  &_bottomLeft {
    flex-shrink: 0;
    margin-top: 0;
    > img {
      width: 220px;
      height: 162px;
      object-fit: contain;
    }
  }

  // .supervisor_bottomRight

  &_bottomRight {
    margin-left: 35px;
    > :first-child {
      margin-top: 0 !important;
    }
  }

  // .supervisor_bottomSummary

  &_bottomSummary {
    > dt {
      font-size: 1.7rem;
      font-weight: bold;
    }
    > dd {
      margin-top: 4px;
      font-size: 1.5rem;
      padding-left: 1em;
    }
  }
}

@media screen and (max-width: $breakpoint-sp) {
  .supervisor {
    // .supervisor_bottom

    &_bottom {
      margin-top: 40px;
    }

    // .supervisor_bottomTitle

    &_bottomTitle {
      > p {
      }
    }

    // .supervisor_bottomMedia

    &_bottomMedia {
      padding: 24px 32px;
      flex-flow: column nowrap;
    }

    // .supervisor_bottomLeft

    &_bottomLeft {
      align-self: center;
      > img {
      }
    }

    // .supervisor_bottomRight

    &_bottomRight {
      margin-left: 0;
      margin-top: 20px;
      > :first-child {
      }
    }

    // .supervisor_bottomSummary

    &_bottomSummary {
      > dt {
      }
      > dd {
      }
    }
  }
}
