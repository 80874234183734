@import "../_config";

.gHeader {
  &_shopBtn {
    &.sp-hidden {
      width: auto;
      > a {
        background-color: transparent;
      }
    }
  }
}

.gHeader {
  // .gHeader_shopBtn

  &_shopBtn {
    > a {
      > i {
        &::before {
          margin-right: 3px;
          top: 3px;
        }
      }
    }
  }
}
